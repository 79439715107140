import React, { Component } from "react";
import "../Styles/header.scss";
import logo from "../Images/logo.png";

class Header extends Component {
  render() {
    return (
      <div className="header">
        <div className="header__content">
          <div className="header__content__info">
            <div className="header__content__info__logo">
              <a
                href="https://mplify.ng"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logo} alt="" />
              </a>
            </div>
            <div className="header__content__info__text"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
