let hostUrl;
let assetHost;

if (window.location.hostname === "localhost") {
   hostUrl = "https://bostageapi.omnibiz.com/api/";
   assetHost = "https://az742041.vo.msecnd.net/vcsites/vcimages/resource/";
} else {
   hostUrl = "https://omniapi.omnibiz.com/api/";
   assetHost = "https://az742041.vo.msecnd.net/vcsites/vcimages/resource/";
}

export const host_url = `${hostUrl}`,
   asset_url = `${assetHost}`;
