import React from "react";

const Spinner = () => (
   <div className="spinner">
      <div className="spinner__cont">
         <span className="spinner__cont__body"></span>
      </div>
   </div>
);

export default Spinner;
