import React, { Component } from "react";
import "../../Styles/invoice.scss";
import { host_url, asset_url } from "../../config";
import dayjs from "dayjs";
import axios from "axios";
import Spinner from "../Utils/Spinner";
import { _formatCurrency } from "../Utils/Helper";
import errorIcon from "../../Images/404-error.svg";

class InvoicePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [{ Description: "", Price: "", Quantity: 1, total: 0 }],
      details: {},
      showPreloader: true,
      showError: false,
      id: "",
      type: 1,
    };
  }

  getInvoiceRequestDetails() {
    const header = {
      headers: {
        "content-type": "application/json",
        source: "PWA",
      },
    };
    axios
      .get(
        `${host_url}Invoice/GetInvoiceRequestDetails?InvoiceRequestId=${this.state.id}&InvoiceType=${this.state.type}`,
        header
      )
      .then((response) => {
        if (
          response.data.StatusCode === 1 &&
          response.data.Result.InvoiceRequestId
        ) {
          this.setState({ details: response.data.Result }, () => {
            this.setState({ showPreloader: false });
          });
        } else {
          this.setState({ showError: true });
        }
      })
      .catch((error) => {
        this.setState({ showError: true });
        console.log(error);
      });
  }

  retry = () => {
    this.setState({ showError: false });
    this.decryptId(
      this.props.match.params.id.replace("pl_s", "+").replace(/sl_h/g, "/")
    );
  };

  decryptId = (id) => {
    const header = {
      headers: {
        "content-type": "application/json",
        countrycode: 1,
        Source: "OnBoarding",
      },
    };
    const data = {
      flag: 2,
      KeyAndValueList: [
        {
          key: "invoiceId",
          value: id,
        },
      ],
    };
    axios
      .post(`${host_url}Onboarding/EncryptAndDecryptString`, data, header)
      .then((response) => {
        if (response.data.StatusCode === 1 && response.data.Result.length > 0) {
          this.setState({ id: Number(response.data.Result[0].value) }, () => {
            this.getInvoiceRequestDetails();
          });
        } else {
          this.setState({ showError: true });
        }
      })
      .catch((error) => {
        this.setState({ showError: true });
      });
  };

  componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      if (this.props.match.params.id) {
        this.decryptId(
          this.props.match.params.id.replace(/pl_s/g, "+").replace(/sl_h/g, "/")
        );
      }
      if (this.props.match.params.type) {
        this.setState({ type: this.props.match.params.type });
      }

      //  this.setState({ id: this.props.match.params.id }, () => {
      //     this.getInvoiceRequestDetails();
      //  });
    }
  }

  render() {
    const details = this.state.details;
    return (
      <div className="invoice-preview">
        {this.state.showError ? (
          <div id="invoice" className="effect2">
            <div className="invoice-preview-error">
              <div>
                <div className="invoice-preview-error-icon">
                  <img src={errorIcon} alt="" />
                </div>
                <div className="invoice-preview-error-header">
                  Unable to load Invoice
                </div>
                <div className="invoice-preview-error-button">
                  <span className="btn small" onClick={() => this.retry()}>
                    Retry
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : this.state.showPreloader ? (
          <div className="invoice-preview-loader">
            <Spinner />
          </div>
        ) : (
          <div id="invoice" className="effect2">
            <div className="invoice-wrapper">
              <div className="invoice-top">
                <div className="invoice-top-info">
                  <div className="flex">
                    <div className="title">
                      <div className="invoice-id">
                        Invoice
                        <span>#{details.InvoiceRequestId}</span>
                      </div>
                      {details.InvoiceCreatedDate ? (
                        <p>
                          Invoice date:{" "}
                          {dayjs(details.InvoiceCreatedDate).format(
                            "MMM D YYYY"
                          )}
                        </p>
                      ) : (
                        <p>
                          Invoice date: {dayjs(new Date()).format("MMM D YYYY")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="invoice-top-business">
                  <div className="flex">
                    {details.CompanyLogo && (
                      <div className="logo">
                        <img
                          src={`${asset_url}${details.CompanyLogo}`}
                          alt=""
                        />
                      </div>
                    )}
                    <div>
                      <div className="invoice-top-business-name">
                        {details.Businessname}
                      </div>
                      <div className="invoice-top-business-address">
                        {details.BusinessAddress}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {(details.CustomerName || details.EnquiryTitle) && (
                <div className="invoice-mid">
                  {details.CustomerName && (
                    <div className="info">
                      {details.OrganizationName ? (
                        <React.Fragment>
                          <h2>
                            {details.OrganizationName || details.CustomerName}
                          </h2>
                          {details.FileNumber && (
                            <p>
                              {`${details.CustomerName} (${details.FileNumber})`}
                            </p>
                          )}
                        </React.Fragment>
                      ) : (
                        <h2>
                          {details.FileNumber
                            ? `${details.CustomerName} (${details.FileNumber})`
                            : details.CustomerName}
                        </h2>
                      )}
                      <p>
                        {details.CustomerPhone || details.CustomerEmail || ""}
                      </p>
                    </div>
                  )}

                  {details.EnquiryTitle && (
                    <div id="project">
                      <h2>Project</h2>
                      <p>{details.EnquiryTitle}</p>
                    </div>
                  )}
                </div>
              )}
              <div className="invoice-bot">
                <div className="table">
                  <table>
                    <tbody>
                      <tr className="tabletitle">
                        <td style={{ width: "20px", paddingRight: "10px" }}>
                          <h2>S/N</h2>
                        </td>
                        <td className="item">
                          <h2>Item Description</h2>
                        </td>
                        <td className="Hours">
                          <h2>Unit Cost</h2>
                        </td>
                        <td className="Rate">
                          <h2>Quantity</h2>
                        </td>
                        <td className="subtotal">
                          <h2>Sub-total</h2>
                        </td>
                      </tr>

                      {details.InvoiceDetailsList.map((item, i) => {
                        return (
                          <tr className="service" key={i}>
                            <td className="tableitem">
                              <p className="itemtext">{i + 1}</p>
                            </td>
                            <td className="tableitem">
                              <p className="itemtext">{item.Description}</p>
                            </td>
                            <td className="tableitem">
                              <p className="itemtext">
                                {_formatCurrency(item.Price)}
                              </p>
                            </td>
                            <td className="tableitem">
                              <p className="itemtext">{item.Quantity}</p>
                            </td>
                            <td className="tableitem">
                              <p className="itemtext">
                                {_formatCurrency(item.Price * item.Quantity)}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="invoice-total">
                  <div className="invoice-total-wrapper">
                    {details.Vat ? (
                      <React.Fragment>
                        <div className="flex">
                          <p>Subtotal</p>
                          <p> {_formatCurrency(details.SubTotal)}</p>
                        </div>
                        <div className="flex">
                          <p>
                            VAT (
                            {Math.round(
                              (details.Vat / details.SubTotal) * 100
                            ) === 5
                              ? "5%"
                              : "7.5%"}
                            )
                          </p>
                          <p> {_formatCurrency(details.Vat)}</p>
                        </div>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                    <div className="flex">
                      <p>
                        <strong>Net Payable:</strong>
                      </p>
                      <p>
                        <strong>{_formatCurrency(details.Total)}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="invoice-subfooter">
                <div>
                  <p className="remarks-header">
                    <strong>Remarks</strong>
                  </p>
                  <p>{details.Remark || <i>No Remarks</i>}</p>
                </div>
                <div>
                  {details.AdvancePayment ? (
                    <div className="light">
                      Advance Payment: {_formatCurrency(details.AdvancePayment)}
                    </div>
                  ) : null}
                  {/* <p>Bank Account Details:</p>
                           <p>
                              Abc EnterPrices <br /> Diamond Bank: 797979799979 <br /> Savings Account{" "}
                           </p> */}
                </div>
              </div>
              {details.AssignedDoctor !== "0" && (
                <div
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    marginBottom: "-27px",
                  }}
                  className="invoice-subfooter"
                >
                  <div>
                    <p className="remarks-header">
                      <strong>Consultant</strong>
                    </p>
                    <p>{details.AssignedDoctor}</p>
                  </div>
                  <div>
                    <p className="remarks-header">
                      <strong>Invoiced by</strong>
                    </p>
                    <p>{details.AssignedNurse}</p>
                  </div>
                </div>
              )}
              {details.DiagnoseName && (
                <div
                  style={{ borderTop: "none" }}
                  className="invoice-subfooter"
                >
                  <div>
                    <p>
                      <strong>Diagnoses</strong>
                    </p>
                    <p>{details.DiagnoseName}</p>
                  </div>
                </div>
              )}
              <div className="invoice-footer">
                <div className="contact-details">
                  <p className="big">Contact Details</p>
                  <p>
                    {details.Email}, {details.Phone}
                  </p>
                </div>
                <div className="vc-logo">
                  <img src={""} alt="" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default InvoicePreview;
