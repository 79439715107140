import React, { Component } from "react";
import InvoiceImg from "../../Images/invoice.png";
import "../../Styles/homepage.scss";

class Home extends Component {
  render() {
    return (
      <div className="homepage">
        <div className="homepage__container">
          <div className="homepage__container__right">
            <img src={InvoiceImg} alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
