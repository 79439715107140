import React, { Component } from "react";
import "./App.scss";
import InvoicePreview from "./Components/Pages/InvoicePreview";
import Home from "./Components/Pages/Home";
import Header from "./Layout/Header";
import { Route, Switch } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/i/:id" component={InvoicePreview} />
          <Route exact path="/i/:id/:type" component={InvoicePreview} />
          <Route component={Home} />
        </Switch>
      </div>
    );
  }
}

export default App;
